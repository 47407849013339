import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";

import { TOKEN_KEY, isAuthenticated } from "./services/auth";

const Solicitacao = lazy(() => import("./screens/Solicitacao"));
const SelecionaRevisao = lazy(() => import("./screens/Revisao"));
const SelecionaRelatorio = lazy(() => import("./screens/Relatorio"));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();

  let query = useQuery();
  let token = query.get("token");

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <>
            {localStorage.setItem(TOKEN_KEY, token)}
            {history.push("/")}
          </>
        ) : isAuthenticated() ? (
          <Component {...props} />
        ) : (
          (window.location.href = `${process.env.REACT_APP_SISTEMA_URL}`)
        )
      }
    />
  );
};

const Routes = () => {
  return (
    <Router>
      <Suspense fallback={<div>Carregando...</div>}>
      <Switch>
        <PrivateRoute exact path="/" component={Solicitacao} />
        <PrivateRoute path="/revisao/:codigo" component={SelecionaRevisao} />
        <PrivateRoute path="/relatorio" component={SelecionaRelatorio} />
        <Redirect from="*" to="/" />
      </Switch>
      </Suspense>
    </Router>
  );
};

export default Routes;
